import React from 'react';
import { IconBuilding, IconChevronRight, IconUserSquare } from '@punchcard/core/icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface IProps extends React.HTMLAttributes<HTMLElement> {
	studentId: number;
	firstName: string;
	lastName: string;
	ssid: string;
	sites: string[];
}

function StudentCard(props: IProps) {
	const { studentId, firstName, lastName, className, ssid, sites } = props;
	return (
		<Link
			className={classNames('student-card', className)}
			to={`${studentId.toString()}/courses?tab=1`}
			state={{ firstName: firstName, lastName: lastName }}
		>
			<div>
				<div>
					{firstName} {lastName}
				</div>
				<div className="d-flex align-items-center">
					<IconUserSquare className="text-gray-400" style={{ width: 18, height: 18 }} />
					<small className="text-sm ms-1">{ssid}</small>
					<div className="rounded bg-gray-400 mx-2" style={{width:4, height:4}}></div>
					<IconBuilding className="text-gray-400 me-1" style={{ width: 18, height: 18 }} />
					<small className="text-sm">{sites?.join(', ')}</small>
				</div>
			</div>
			<IconChevronRight />
		</Link>
	);
}

export default StudentCard;