
import { IconChevronDown } from '@punchcard/core/icons';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
interface IProps {
	documentList: TeacherCourseDocumentDTO[] | undefined;
	handleTabClick: (index: number) => void;
	activeDocument: number;
}
const ResourceSelect = (props: IProps) => {
	const { t } = useTranslation();
	const { documentList, handleTabClick } = props;
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const toggleDropdown = () => {
		setIsOpen((prev) => !prev);
	};
	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	const handleClick = (docId: number) => {
		handleTabClick(docId);
		setIsOpen(false);
	};
	return (
		<div className="d-flex position-relative" ref={dropdownRef}>
			<button
				className={'unit-tab flex-fill d-flex align-items-center justify-content-center'}
				onClick={() => {
					toggleDropdown();
				}}
			>
				<IconChevronDown />
			</button>
			{isOpen &&
				<div className="bg-quarternary-400 text-white position-absolute border rounded-3" style={{ top: 50, right: -16, width: '300px', zIndex: 1000 }}>
					{documentList?.map((doc: TeacherCourseDocumentDTO) => (
						<button
							key={`docSelect-${doc?.id}`}
							className="unit-tab rounded-0 flex-fill d-flex w-100"
							onClick={() => handleClick(doc.id)}
						>
							{t(`documentType.${doc.documentTypeCode}`)}
						</button>
					))}
				</div>
			}
		</div>
	);
};

export default ResourceSelect;
