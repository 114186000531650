import React from 'react';
import { Button } from './Button';

interface IProps {
    textButton: string;
    iconButton?: React.ReactElement;
    textClear: string;
    iconClear?: React.ReactElement;
    onClickButton?: () => void;
    onClickClear?: () => void;
}

function ToolbarTable(props: IProps) {
	const { textButton, iconButton, textClear, iconClear, onClickButton, onClickClear } = props;
	return (
		<div className="toolbar-container">
			<Button className="toolbar-button" onClick={onClickButton}>
				{iconButton}
				<span className="ms-1">{textButton}</span>
			</Button>
			<Button className="toolbar-clear" onClick={onClickClear}>
				{iconClear}
				<span className="ms-1">{textClear}</span>
			</Button>
		</div>
	);
}

export default ToolbarTable;