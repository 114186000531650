import React from 'react';
import { IconAnswerKey, IconInstructorManual, IconReader, IconStudyGuide } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
interface IProps {
	documents: TeacherCourseDocumentDTO[] | undefined;
	handleTabClick: (docId: number) => void;
}

const ResourceGrid = (props: IProps) => {
	const { documents, handleTabClick } = props;
	const { t } = useTranslation();
	const sortedDocuments = documents?.sort((x, y) => x.documentTypeSort - y.documentTypeSort);
	const getDocumentTypeIcon = (documentTypeCode: string) => {
		switch (documentTypeCode) {
			case 'AnswerKey':
				return <IconAnswerKey className="thumbnail-icon" style={{ fill: 'none' }} />;
			case 'InstructorManual':
				return <IconInstructorManual className="thumbnail-icon" style={{ fill: 'none' }} />;
			case 'Reader':
				return <IconReader className="thumbnail-icon" style={{ fill: 'none' }} />;
			case 'StudyGuide':
				return <IconStudyGuide className="thumbnail-icon" style={{ fill: 'none' }} />;
			default:
				return null;
		}
	};
	return (
		<React.Fragment>
			<div className="row">
				{sortedDocuments && sortedDocuments.map((image) => (
					<div className={'col-4'} key={`image-${image.id}`}>
						<button
							className={'thumbnail-button bg-white border-0 w-100 position-relative'}
							onClick={() => handleTabClick(image.id)}
						>
							<img src={image.documentThumbnail} alt={image.documentTypeName} className="thumbnail-image border border-secondary-100 hover-zoom rounded-3" />
							<div className="thumbnail-overlay bg-secondary-100 rounded-3 position-absolute flex align-items-center justify-content-center pt-2">
								{getDocumentTypeIcon(image.documentTypeCode)}
							</div>
							<p className="m-0 pt-2 pb-2">{t(`documentType.${image.documentTypeCode}`)}</p>
						</button>
					</div>
				))}
			</div>

		</React.Fragment>
	);
};

export default ResourceGrid;
