import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface IProps {
    count: number;
    children: ReactNode;
    style?: React.CSSProperties;
}

function Badge(props: IProps) {
	const { count, children, style } = props;
	return (
		<div className={classNames('position-relative inline-block', style)}>
			{children}
			{count > 0 && (
				<span className="custom-badge">
					{count}
				</span>
			)}
		</div>
	);
}

export default Badge;