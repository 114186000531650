import React from 'react';
import ReactTable, { RowInfo } from 'react-table';
import Pagination from './Pagination';
import classNames from 'classnames';
import ToolbarTable from './ToolbarTable';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IProps<T extends { id: string }> {
	columns: Array<{ id?: string;[key: string]: unknown }>;
	fetchData: (
		currentPage: number, pageSize: number, sortBy: { id: string; desc: boolean } | null, searchText: string | undefined, filters: Record<string, any>, selectedItems: string[]
	) => Promise<{ data: T[], totalItems: number } | null>;
	fetchSelectAll: (currentPage: number, pageSize: number, sortBy: { id: string; desc: boolean } | null) => Promise<T[] | null>;
	filters?: Record<string, any>,
	searchText?: string;
	getTableProps?: (...args: any[]) => object;
	getTheadFilterThProps?: (...args: any[]) => object;
	getTheadGroupProps?: (...args: any[]) => object;
	getTheadProps?: (...args: any[]) => object;
	getTheadThProps?: (...args: any[]) => object;
	getTbodyProps?: (...args: any[]) => object;
	getTrProps?: (...args: any[]) => object;
	getTdProps?: (...args: any[]) => object;
	onRowClick?: ({ row, original }: { row?: any, original?: any }) => void;
	className?: string;
	minRows?: number;
	hasActions?: boolean;
	resizable?: boolean;
	sortable?: boolean;
	onFilteredChange?: any;
	noDataText?: string;
	showPagination?: boolean;
	filterable?: boolean;
	TableComponent?: any;
	toolbar?: boolean;
	toolbarButtonText?: (count: number) => string | string;
	toolbarButtonIcon?: React.ReactElement;
	toolbarClearText?: string;
	toolbarClearIcon?: React.ReactElement;
	toolbarButtonOnClick?: (selectedRows: number[]) => Promise<void>;
	toolbarClearOnClick?: () => void;
	refresh?: boolean;
	setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ServerSideTable<T extends { id: string }>(props: IProps<T>) {
	const [data, setData] = React.useState<T[]>([]);
	const [currentPage, setCurrentPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [totalItems, setTotalItems] = React.useState(0);
	const [sortBy, setSortBy] = React.useState<{ id: string; desc: boolean } | null>(null);
	const [loading, setLoading] = React.useState(false);
	const [selectedRows, setSelectedRows] = React.useState<T[]>([]);


	const {
		className,
		filterable,
		TableComponent,
		showPagination = true,
		columns = [],
		fetchData,
		fetchSelectAll,
		filters = {},
		searchText,
		getTableProps = () => {
			return { className: 'table overflow-y-scroll' };
		},
		getTheadFilterThProps = () => {
			return { className: 'th' };
		},
		getTheadGroupProps = () => {
			return { className: 'thead theadgroup' };
		},
		getTheadProps = () => {
			return { className: 'thead border-gray border-bottom bg-light' };
		},
		getTheadThProps = () => {
			return { className: 'td px-3 py-2  d-flex align-items-center' };
		},
		getTbodyProps = () => {
			return { className: 'tbody overflow-visible' };
		},
		getTdProps = () => {
			return { className: 'td px-3 py-2  d-flex align-items-center' };
		},
		minRows = 0,
		resizable = true,
		sortable = true,
		noDataText,
		toolbar,
		toolbarButtonText,
		toolbarButtonIcon,
		toolbarClearText,
		toolbarClearIcon,
		toolbarButtonOnClick,
		toolbarClearOnClick,
		refresh,
		setRefresh,
	} = props;

	const buttonTextToolbar = typeof toolbarButtonText === 'function' ? toolbarButtonText(selectedRows.length) : toolbarButtonText;


	const loadData = async (sendSelectedItems: boolean = true) => {
		try {
			setLoading(true);
			const result = await fetchData(currentPage, pageSize, sortBy, searchText, filters, sendSelectedItems ? selectedRows.map(item => item.id): [] );
			setData(result?.data || []);
			setTotalItems(result?.totalItems || 0);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
			setLoading(false);
		}
	};

	React.useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, pageSize, sortBy, searchText, filters]);

	React.useEffect(() => {
		if (refresh) {
			loadData(false);
			if (setRefresh) {
				setRefresh(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh]);

	const getTrProps = (_: any, rowInfo: any) => {
		const { onRowClick } = props;
		if (rowInfo && rowInfo.original) {
			const isRowSelected = selectedRows.includes(rowInfo.original.id);

			return {
				className: classNames('tr', {
					'cursor-pointer': onRowClick,
					'p-0': !onRowClick,
					'checked-row': isRowSelected,
				}),
				onClick: (e: any, handleOriginal: any) => {
					if (onRowClick) {
						onRowClick(rowInfo);
					}
					if (handleOriginal) {
						handleOriginal();
					}
				},
			};
		} else {
			return {
				className: classNames('tr', {
					'cursor-pointer': onRowClick,
					'p-0': !onRowClick,
				}),
			};
		}
	};


	function onPageChange(page: number) {
		setCurrentPage(page);
	}

	function onPageSizeChange(size: number) {
		setPageSize(size);
	}

	async function onButtonOnClick() {
		if (toolbarButtonOnClick) {
			await toolbarButtonOnClick(selectedRows.map(item => Number(item.id)));
			setSelectedRows([]);
			loadData(false);
		}
	}
	const handleSelectAll = async () => {
		try {
			if (selectedRows.length === 0) {

				const allStudentIds = await fetchSelectAll(currentPage, pageSize, sortBy);

				if (!allStudentIds) {
					console.error('Failed to fetch student IDs.');
					return;
				}

				const parsedIds = allStudentIds.map((row) => ({
					...row,
					id: parseInt(row.id, 10),
				}));
				setSelectedRows(parsedIds);
			} else {
				setSelectedRows([]);
			}
		} catch (error) {
			console.error('Error in handleSelectAll:', error);
		}
	};

	const handleRowSelect = (id: string) => {
		setSelectedRows(prev => {
			const updated = prev.some(row => row.id === id)
				? prev.filter(row => row.id !== id)
				: [...prev, data.find(row => row.id === id)!];
			return updated;
		});
	};

	const enhancedColumns = columns.map(column => {
		if (column.id === 'checkbox') {
			return {
				...column,
				Header: () => (
					<input
						type="checkbox"
						checked={data.length > 0 && selectedRows.length > 0 && selectedRows.length >= totalItems}
						onChange={handleSelectAll}
					/>
				),
				Cell: (row: RowInfo) => (
					<input
						type="checkbox"
						checked={selectedRows.some((selectedRow) => selectedRow.id === row.original.id)}
						onChange={() => handleRowSelect(row.original.id)}
					/>
				),
			};
		}
		return column;
	});

	return (
		<React.Fragment>
			<ReactTable
				className={classNames('data-table, server-data-table', className)}
				style={{ height: 1}}
				filterable={filterable}
				TableComponent={TableComponent}
				manual={true}
				data={data}
				columns={enhancedColumns}
				pageSize={pageSize}
				showPagination={showPagination}
				multiSort={false}
				pages={Math.ceil(totalItems / pageSize)}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				loading={loading}
				minRows={minRows}
				sortable={sortable}
				resizable={resizable}
				onSortedChange={(newSorted) => {
					const sort = newSorted[0] || null;
					setSortBy(sort);
				}}
				PaginationComponent={() => (
					<Pagination
						totalItems={totalItems}
						itemsPerPage={pageSize}
						currentPage={currentPage}
						onPageChange={onPageChange}
						onPageSizeChange={onPageSizeChange}
					/>
				)}
				getTableProps={getTableProps}
				getTheadFilterThProps={getTheadFilterThProps}
				getTheadGroupProps={getTheadGroupProps}
				getTheadProps={getTheadProps}
				getTheadThProps={getTheadThProps}
				getTbodyProps={getTbodyProps}
				getTrProps={getTrProps}
				getTdProps={getTdProps}
				noDataText={noDataText}
			/>
			{selectedRows.length > 0 && toolbar &&
				<div className="d-flex justify-content-center">
					<ToolbarTable
						textButton={buttonTextToolbar || ''}
						iconButton={toolbarButtonIcon}
						textClear={toolbarClearText || ''}
						iconClear={toolbarClearIcon}
						onClickButton={onButtonOnClick}
						onClickClear={toolbarClearOnClick || (() => setSelectedRows([]))}
					/>
				</div>
			}
		</React.Fragment>
	);
}

export default ServerSideTable;