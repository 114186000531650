import React, { useState, SVGProps } from 'react';
import logoExpend from '@punchcard/core/assets/lyla-logo.png';
import logoCollapse from '@punchcard/core/assets/lyla-logo-collapse.png';
import { IconBuilding, IconDoubleArrowLeft, IconDoubleArrowRight, IconGroupePeople, IconHome, IconLogout, IconManageAccounts } from '@punchcard/core/icons';
import SideBarItem from './SideBarItem';
import { Link, useLocation } from 'react-router-dom';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AuthContext } from 'context/AuthContext';
import { hasAnyRequiredPermission } from 'auth/Permissions';
import { Button } from '@punchcard/core';
import i18next from 'i18next';
import tenantAPI from 'api/tenantAPI';

interface SidebarMenu {
	id: number;
	icon: React.ComponentType<SVGProps<SVGSVGElement>>;
	label: string;
	to: string;
}

const SideBar = () => {
	const { t } = useTranslation();
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const { currentUser } = React.useContext(AuthContext);
	const hasSitePermissions = hasAnyRequiredPermission(currentUser?.permissions, ['ReadSite', 'WriteSite']);
	const hasRosterPermissions = hasAnyRequiredPermission(currentUser?.permissions, ['ManageRoster']);
	const sidebarItems: SidebarMenu[] = [
		{ id: 1, icon: IconHome, label: t('sidebar.home'), to: '/' },
		{ id: 2, icon: IconGroupePeople, label: t('sidebar.my_roster'), to: '/students?page=1&itemsPerPage=10' },
	];

	const toggleSidebar = () => {
		setCollapsed(!collapsed);
	};

	const location = useLocation();
	const { instance }: IMsalContext = useMsal();

	const comparePaths = (to: string, pathname: string): boolean => {
		const indexTo = to.indexOf('?');
		const toWithoutParams = indexTo === -1 ? to : to.substring(0, indexTo);
		const cleanTo = toWithoutParams.split('/')[1];

		const indexPathName = pathname.indexOf('?');
		const pathNameWithoutParams = indexPathName === -1 ? pathname : pathname.substring(0, indexPathName);
		const cleanPathName = pathNameWithoutParams.split('/')[1];

		return cleanPathName === cleanTo;
	};


	return (
		<nav
			className={classNames('sidebar bg-quaternary-400 border-gray-500 border-end', { 'sidebar-close': collapsed, 'sidebar-open': !collapsed })}
			aria-label="Main Navigation"
			role="navigation"
		>
			<div className="p-2 pt-3">
				<img src={collapsed ? logoCollapse : logoExpend} alt="Lyla Logo" className={classNames('d-block mb-4', { 'px-2': collapsed, 'px-3': !collapsed })} />
				<ul className="nav flex-column">
					{sidebarItems.map((item) => (
						<li className="nav-item rounded" key={item.id}>
							<Link
								className="nav-link text-dark p-0 rounded"
								to={item.to}
							>
								<SideBarItem
									icon={item.icon}
									label={item.label}
									collapsed={collapsed}
									className={classNames({ 'active': comparePaths(item.to, location.pathname) })}
								/>
							</Link>
						</li>
					))}
					{(hasRosterPermissions || hasSitePermissions) &&
						<div className="border-top border-gray-200 mt-3">
							{!collapsed && <div className="fw-bold text-gray-600 mt-3"><small>{t('sidebar.settings')}</small></div>}
						</div>
					}
					{hasRosterPermissions &&
						<li>
							<Link
								className="nav-link text-dark p-0 rounded"
								to={'/educators'}
							>
								<SideBarItem
									icon={IconManageAccounts}
									label={t('sidebar.all_students')}
									collapsed={collapsed}
									className={classNames(collapsed ? 'mt-3' : 'mt-2', { 'active': comparePaths('/educators', location.pathname) })}
								/>
							</Link>
						</li>
					}
					{hasSitePermissions &&
						<li className="nav-item rounded">
							<Link
								className="nav-link text-dark p-0 rounded"
								to={'/sites'}
							>
								<SideBarItem
									icon={IconBuilding}
									label={t('sidebar.sites')}
									collapsed={collapsed}
									className={classNames({ 'active': comparePaths('/sites', location.pathname) })}
								/>
							</Link>
						</li>
					}
				</ul>
			</div>
			<div>
				<div className="d-flex flex-column p-2">
					<Link
						className="nav-link text-dark rounded"
						to="/AzureADB2C/Account/SignOut"
						onClick={() => instance.logoutRedirect()}
					>
						<SideBarItem icon={IconLogout} label={t('sidebar.logout')} collapsed={collapsed} />

					</Link>
				</div>
				<div className="border-top border-gray-200 p-2">
					<button
						className="nav-link text-dark rounded w-100"
						onClick={toggleSidebar}
						aria-label="Toggle Sidebar"
					>
						<SideBarItem icon={collapsed ? IconDoubleArrowRight : IconDoubleArrowLeft} label={t('sidebar.collapse')} reverse={true} collapsed={collapsed} />
					</button>
				</div>
			</div>
		</nav>
	);
};

export default SideBar;