import React, { useContext, useEffect, useState } from 'react';
import { Button, Page, PageHero, Pagination, Container, LoadingScreen, SearchBar, ModalOverlay, FormGroup, Select } from '@punchcard/core';
import { IconChevronDown, IconChevronUp, IconClose, IconFilterList } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PlaneStarLogo from '@punchcard/core/assets/plane-start-logo.png';
import StudentCard from 'components/students/StudentCard';
import { StudentContext } from 'context/StudentContext';
import { Controller, FormProvider, useForm } from 'react-hook-form';

function Students() {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { students, loading, handleSort, sort } = useContext(StudentContext);
	const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') || '1', 10));
	const [itemsPerPage, setItemsPerPage] = useState(parseInt(searchParams.get('itemsPerPage') || '25', 10));
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredStudents, setFilteredStudents] = useState(students);
	const indexOfLastItem = currentPage * itemsPerPage + itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentStudents = filteredStudents?.slice(indexOfFirstItem, indexOfLastItem);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
	const filteredSites = students
		.map(student => student.sites)
		.flat()
		.reduce((acc, site) => {
			if (!acc.some(item => item.value === site)) {
				acc.push({ label: site, value: site });
			}
			return acc;
		}, [] as { label: string; value: string }[])
		.sort((a, b) => a.label.localeCompare(b.label));


	const methods = useForm<{ sites: string[] }>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			sites: [],
		},
	});

	const { control, watch } = methods;

	const selectedSites: string[] = watch('sites');

	useEffect(() => {
		if (appliedFilters.length > 0) {
			const filtered = students?.filter(student =>
				student.sites.some(site => appliedFilters.includes(site))
			);
			setFilteredStudents(filtered);
		} else {
			setFilteredStudents(students);
		}
	}, [appliedFilters, students, selectedSites]);

	useEffect(() => {
		const pageParam = parseInt(searchParams.get('page') || '1', 10);
		const itemsPerPageParam = parseInt(searchParams.get('itemsPerPage') || '25', 10);

		setCurrentPage(pageParam - 1);
		setItemsPerPage(itemsPerPageParam);
	}, [searchParams]);

	useEffect(() => {
		if (searchTerm) {
			const filtered = students?.filter(student =>
				student.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
				student.lastName.toLowerCase().includes(searchTerm.toLowerCase())
			);
			setFilteredStudents(filtered);
		} else {
			setFilteredStudents(students);
		}
	}, [searchTerm, students]);

	const onPageChange = (page: number) => {
		navigate(`?page=${page + 1}&itemsPerPage=${itemsPerPage}`);
	};

	const onPageSizeChange = (size: number) => {
		navigate(`?page=${currentPage + 1}&itemsPerPage=${size}`);
	};

	const onSearchChange = (value: string) => {
		setSearchTerm(value);
	};

	function headerModal() {
		return (
			<div className="d-flex justify-content-between flex-grow-1">
				<div className="d-flex align-items-center">
					{t('educators.filters')}
					{selectedSites.length > 0 && <div className="ms-2 rounded-3 bg-primary-100 px-2 py-1">{selectedSites.length}</div>}
				</div>
				<div>
					<Button
						className="btn-ghost-primary pe-0"
						onClick={() => { setOpenModal(false); }}
						icon={<IconClose className="icon-22" />}
						iconPosition="right"
					>
						{t('navigation.close')}
					</Button>
				</div>
			</div>
		);
	}

	const confirmFilters = () => {
		setAppliedFilters(selectedSites || []); // Update applied filters with current selection
		setOpenModal(false); // Close the modal
	};

	if (loading) {
		return (
			<LoadingScreen />
		);
	}

	return (
		<Page className="d-flex flex-column">
			<PageHero title={t('students.your_students')} className="justify-content-start">
				<img src={PlaneStarLogo} alt="Lyla Logo" className="d-block px-2" />
			</PageHero>
			<Container
				className="pb-3 px-3 d-flex flex-column flex-fill"
				classNameEmpty="border rounded mx-3 mb-3"
				isEmpty={!students || students?.length === 0}
				emptymessage={t('students.no_students')}
				descriptionmessage={t('students.description_no_students')}
			>
				<div className="d-flex flex-column justify-content-between">
					<div className="d-flex justify-content-between align-items-center pb-2">
						<div className="d-flex align-items-center">
							<h6>{t('students.students')}</h6>
							<Button
								className="btn-icon btn-ghost-primary ms-2 p-0"
								role="button"
								aria-label={sort === 'asc' ? 'Sort students in ascending order' : 'Sort students in descending order'}
								onClick={handleSort}
							>
								{sort === 'desc' ? <IconChevronDown /> : <IconChevronUp />}
							</Button>
						</div>
						<div className="d-flex align-items-center">
							<SearchBar
								placeholder={t('students.search_by_student_name')}
								style={{ width: 300 }}
								onChange={onSearchChange}
								value={searchTerm}
							/>
							<Button className="btn-ghost-primary p-1 ms-1" onClick={() => setOpenModal(true)} icon={<IconFilterList className="ms-2" />} ></Button>
						</div>

					</div>
					{currentStudents?.map(student => (
						<StudentCard
							key={student.id}
							studentId={student.id}
							firstName={student.firstName}
							lastName={student.lastName}
							ssid={student.ssid}
							sites={student.sites}
							className="mb-2"
						/>
					))}
					<div className="mt-2">
						<Pagination
							currentPage={currentPage}
							itemsPerPage={itemsPerPage}
							onPageChange={onPageChange}
							onPageSizeChange={onPageSizeChange}
							totalItems={filteredStudents?.length}
						/>
					</div>
				</div>
				<ModalOverlay
					isOpen={openModal}
					modalSize="lg"
					onRequestClose={() => {
						setOpenModal(false);
					}}
					headerChildren={headerModal()}
					confirmButtonChildren={t('educators.view_student', {
						count: students?.filter(student =>
							student?.sites?.some(site => selectedSites.includes(site))
						).length || students?.length
					})}
					cancelButtonChildren={t('educators.clear_all_filters')}
					confirmButtonAction={() => { confirmFilters(); }}
					cancelButtonAction={() => {
						methods.setValue('sites', []);
					}}
					modalPosition="right"
					hideCloseButton={true}
					shouldCloseOnOverlayClick={false}
				>
					<div>
						<FormProvider {...methods}>
							<Controller
								control={control}
								name="sites"
								render={({ field, fieldState }) =>
									<FormGroup fieldState={fieldState} label={t('sites.sites')}>
										<Select
											options={filteredSites}
											isMulti={true}
											{...field}
										/>
									</FormGroup>
								}
							/>
						</FormProvider>
					</div>
				</ModalOverlay >
			</Container>
		</Page>
	);
}

export default Students;