import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { IconClose, IconSearch } from '@punchcard/core/icons';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface IProps {
	placeholder?: string;
	value?: string;
	onChange: (value: string) => void;
	className?: string;
	style?: React.CSSProperties;
	searchIcon?: boolean;
	tooltipText?: string;
}

function SearchBar(props: IProps) {
	const { placeholder, value = '', onChange, className, style, searchIcon = true } = props;
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState(value);

	const debouncedChange = debounce((input: string) => {
		onChange(input);
	}, 300);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setInputValue(newValue);
		if (newValue.length >= 3 || newValue === '') {
			debouncedChange(newValue);
		}
	};

	const handleClear = () => {
		setInputValue('');
		debouncedChange.cancel();
		onChange('');
	};

	return (
		<div className={classNames('searchbar', className)} style={style}>
			<Tooltip id="my-tooltip" place="top" />
			{searchIcon && <IconSearch className="text-gray-700" style={{ width: 27, height: 27 }} />}
			<input
				className={classNames('border-0 flex-grow-1 text-gray-700', { 'ps-2': searchIcon })}
				placeholder={placeholder}
				value={inputValue}
				onChange={handleInputChange}
			/>
			{inputValue && <span
				data-tooltip-id="my-tooltip"
				data-tooltip-content={t('educators.clear')}
			>
				<IconClose
					className="text-gray-700"
					style={{ width: 27, height: 27 }}
					onClick={handleClear}
					role="button"
				/>
			</span>}
		</div >
	);
}

export default SearchBar;