// StudentContext.tsx
import studentsApi from 'api/studentsApi';
import React, { createContext, useState, useEffect } from 'react';

interface IProps extends React.PropsWithChildren {
	loading: boolean;
	setLoading: (isInitializing: boolean) => void;
}

interface StudentContextType {
	students: TeacherStudentDTO[];
	loading: boolean;
	handleSort: () => void;
	sort: string;
}

const initialContext: StudentContextType = {
	students: [],
	loading: true,
	handleSort: () => { },
	sort: 'asc',
};

const StudentProvider = (props: IProps) => {
	const [students, setStudents] = useState<TeacherStudentDTO[]>([]);
	const [sort, setSort] = useState<string>('desc');
	const { loading, setLoading, children } = props;

	const handleSort = () => {
		const studentsSorted = students?.sort((a, b) => {
			if (sort === 'asc') {
				setSort('desc');
				return a.firstName.localeCompare(b.firstName);
			} else {
				setSort('asc');
				return b.firstName.localeCompare(a.firstName);
			}
		});
		return studentsSorted;
	};

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const data = await studentsApi.getStudentsForTeacher();
				setStudents(data.sort((a, b) => a.firstName.localeCompare(b.firstName)));
			} catch (error) {
				console.error('Error fetching students: ', error);
			} finally {
				setLoading(false);
			}
		};
		fetchStudents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StudentContext.Provider value={{ students, loading, handleSort, sort }}>
			{children}
		</StudentContext.Provider>
	);
};

export default StudentProvider;
export const StudentContext = createContext<StudentContextType>(initialContext);
