import React, { useEffect, useState } from 'react';
import { IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp, IconClose, IconFilterList, IconSearch } from '@punchcard/core/icons';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Badge, Button, FormGroup, ModalOverlay, SearchBar, Select } from '@punchcard/core';
import { t } from 'i18next';
import { FormProvider, Controller, useForm } from 'react-hook-form';

interface IProps {
	items: Item[];
	title: string;
	filter?: boolean;
}
interface Item {
	id: number;
	name: string;
	to: string;
	ssid: string;
	sites: string[];
}

const ITEMS_PER_PAGE = 10;

const SecondSideBar = (props: IProps) => {
	const { items, title, filter = false } = props;
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [sort, setSort] = useState<string>('asc');
	const [searchBarDisplay, setSearchBarDisplay] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [appliedFilters, setAppliedFilters] = useState<string[]>([]);

	const methods = useForm<{ sites: string[] }>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			sites: [],
		},
	});

	const { control, watch } = methods;
	const selectedSites: string[] = watch('sites');

	const comparePaths = (to: string, pathname: string): boolean => {
		const indexTo = to.indexOf('?');
		const toWithoutParams = indexTo === -1 ? to : to.substring(0, indexTo);

		const indexPathName = pathname.indexOf('?');
		const pathNameWithoutParams = indexPathName === -1 ? pathname : pathname.substring(0, indexPathName);

		return toWithoutParams === pathNameWithoutParams;
	};

	useEffect(() => {
		const activeItem = items.find(item => comparePaths(item.to, location.pathname));

		if (activeItem) {
			const activeIndex = items.indexOf(activeItem);
			const page = Math.ceil((activeIndex + 1) / ITEMS_PER_PAGE);
			setCurrentPage(page);
		}
	}, [location.pathname, items]);

	const indexOfLastItem: number = currentPage * ITEMS_PER_PAGE;
	const indexOfFirstItem: number = indexOfLastItem - ITEMS_PER_PAGE;
	const filteredItems: Item[] = items?.filter(item => {
		// Check if the item's name includes the search term
		const matchesSearchTerm = item.name.toLowerCase().includes(searchTerm.toLowerCase());

		// Check if the item matches any of the applied filters
		const matchesFilters = appliedFilters.length === 0 || appliedFilters.some(filter =>
			item.sites?.includes(filter) // Replace 'attributes' with the relevant field in your Item model
		);

		return matchesSearchTerm && matchesFilters; // Item must match both conditions
	}) || [];
	const currentItem: Item[] = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber: number): void => setCurrentPage(pageNumber);

	const handleSort = (): void => {
		setSort(sort === 'asc' ? 'desc' : 'asc');
		items.sort((a, b) => {
			if (sort === 'asc') {
				return a.name.localeCompare(b.name);
			}
			return b.name.localeCompare(a.name);
		});
	};

	const handleSearch = (): void => {
		setSearchBarDisplay(!searchBarDisplay);
	};

	const handleFilter = (): void => {
		setOpenModal(true);
	};

	const handleSearchChange = (value: string): void => {
		setSearchTerm(value);
		setCurrentPage(1);
	};

	const confirmFilters = () => {
		setAppliedFilters(selectedSites || []);
		setOpenModal(false);
	};


	const filteredSites = filter ? items
		.map(item => item.sites)
		.flat()
		.reduce((acc, site) => {
			if (!acc.some(item => item.value === site)) {
				acc.push({ label: site, value: site });
			}
			return acc;
		}, [] as { label: string; value: string }[])
		.sort((a, b) => a.label.localeCompare(b.label))
		: [];

	function headerModal() {
		return (
			<div className="d-flex justify-content-between flex-grow-1">
				<div className="d-flex align-items-center">
					{t('educators.filters')}
					{selectedSites.length > 0 && <div className="ms-2 rounded-3 bg-primary-100 px-2 py-1">{selectedSites.length}</div>}
				</div>
				<div>
					<Button
						className="btn-ghost-primary pe-0"
						onClick={() => { setOpenModal(false); }}
						icon={<IconClose className="icon-22" />}
						iconPosition="right"
					>
						{t('navigation.close')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<nav className="sidebar secondary-sidebar bg-light border-end border-gray-300" aria-label="Main Navigation" role="navigation">
			<div className="d-flex flex-column p-2 pt-3 secondary-sidebar">
				<div className="d-flex align-items-center mb-3">
					<h6>{title}</h6>
					<div className="d-flex flex-grow-1 justify-content-between">
						<Button
							className="btn-icon btn-ghost-primary p-0"
							role="button"
							aria-label={sort === 'asc' ? 'Sort students in ascending order' : 'Sort students in descending order'}
							onClick={handleSort}
						>
							{sort === 'desc' ? <IconChevronDown /> : <IconChevronUp />}
						</Button>
						<Button
							className="btn-icon btn-ghost-primary p-0"
							role="button"
							aria-label={'search students'}
							onClick={handleSearch}
							icon={<IconSearch />}
						/>
						{filter &&
							<Badge
								count={selectedSites.length}
							>
								<Button
									className="btn-icon btn-ghost-primary p-0"
									role="button"
									aria-label={'filter students'}
									onClick={handleFilter}
									icon={<IconFilterList />}
								/>
							</Badge>
						}
					</div>
				</div>
				{searchBarDisplay && <SearchBar className="mb-3" searchIcon={false} onChange={handleSearchChange} />}
				<ul className="nav flex-column">
					{currentItem.map((item: Item) => (
						<li className="nav-item rounded w-100" key={item.id}>
							<Link
								className="nav-link text-dark p-0 rounded"
								to={item.to}
								state={{ name: item.name }}
							>
								<div className={classNames(
									'sidebar-item rounded p-2 overflow-hidden text-nowrap text-truncate',
									{ 'active': comparePaths(item.to, location.pathname) }
								)}
								>
									<div className="d-flex flex-column">
										{item.name}
										{item.ssid && <small className="text-quaternary-800 fw-normal">
											{item.ssid}
										</small>}
									</div>

								</div>
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div>
				<div className="p-2 d-flex justify-content-start">
					<Button
						className="btn-ghost-primary ms-2 p-0"
						onClick={() => paginate(currentPage - 1)}
						aria-label="Previous Page"
						disabled={currentPage === 1}
					>
						<IconChevronLeft className="icon-22" />
					</Button>
					<Button
						className="btn-icon btn-ghost-primary ms-2 p-0"
						onClick={() => paginate(currentPage + 1)}
						aria-label="Next Page"
						disabled={indexOfLastItem >= items.length}
					>
						<IconChevronRight className="icon-22" />
					</Button>
				</div>
				{filter &&
					<ModalOverlay
						isOpen={openModal}
						modalSize="lg"
						onRequestClose={() => {
							setOpenModal(false);
						}}
						headerChildren={headerModal()}
						confirmButtonChildren={t('educators.view_student', {
							count: items?.filter(item =>
								item?.sites?.some(site => selectedSites.includes(site))
							).length || items?.length
						})}
						cancelButtonChildren={t('educators.clear_all_filters')}
						confirmButtonAction={() => { confirmFilters(); }}
						cancelButtonAction={() => {
							methods.setValue('sites', []);
						}}
						modalPosition="right"
						hideCloseButton={true}
						shouldCloseOnOverlayClick={false}
					>
						<div>
							<FormProvider {...methods}>
								<Controller
									control={control}
									name="sites"
									render={({ field, fieldState }) =>
										<FormGroup fieldState={fieldState} label={t('sites.sites')}>
											<Select
												options={filteredSites}
												isMulti={true}
												{...field}
											/>
										</FormGroup>
									}
								/>
							</FormProvider>
						</div>
					</ModalOverlay >
				}
			</div>
		</nav>
	);
};

export default SecondSideBar;
