import React from 'react';
import { ActionIcon, Container, DataTable, LoadingScreen, Page, PageHero } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import educatorsApi from 'api/educatorApi';
import { toast } from 'react-toastify';
import { RowInfo } from 'react-table';
import { IconEditUnfilled } from '@punchcard/core/icons';
import { useNavigate } from 'react-router-dom';

function EducatorsList() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [educators, setEducators] = React.useState<TeacherEducatorDTO[]>([]);
	const [loading, setLoading] = React.useState(true);

	const columns = [
		{
			Header: 'User ID',
			accessor: 'userId',
			show: false
		},
		{
			Header: t('educators.name'),
			accessor: 'name',
		},
		{
			Header: t('educators.nb_students'),
			accessor: 'nbStudents',
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<ActionIcon title="Manage" icon={IconEditUnfilled} onClick={() => navigate(`/educators/${rowInfo.original.id}`, {
						state: { name: `${rowInfo.original.name}` }})}
					/>
				</React.Fragment>,
			Header: '',
			showClearButton: true,
			id: 'Actions'
		},
	];

	React.useEffect(() => {
		async function getEducators() {
			setLoading(true);
			const educators = await educatorsApi.educators.getEducators();
			if (educators !== null) {
				setEducators(educators);
			} else {
				toast.error(t('educators.unable_to_retrieve_educators_list'));
			}
			setLoading(false);
		}
		getEducators();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<Page title={t('educators.educators')} className="d-flex flex-column">
			<PageHero title={t('educators.educators')} className="justify-content-start" />
			<Container
				className="pb-3 px-3 d-flex flex-column flex-fill"
				classNameEmpty="border rounded mx-3 mb-3"
				isEmpty={!educators || educators?.length === 0}
				emptymessage={t('educators.no_educators')}
				descriptionmessage={t('educators.description_no_educators')}
			>
				<DataTable
					data={educators}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('educators.no_educators')}
					loading={loading}
				/>
			</Container>
		</Page>
	);
}

export default EducatorsList;