import { Badge, Button, Container, FormGroup, LoadingScreen, ModalOverlay, Page, SearchBar, Select, ServerSideTable } from '@punchcard/core';
import { IconAdd, IconArrowLeft, IconClose, IconFilterList, IconRemove } from '@punchcard/core/icons';
import educatorsApi from 'api/educatorApi';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RowInfo } from 'react-table';

function Educators() {
	const { t } = useTranslation();
	const { educatorId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const educatorNameFromState = location.state?.name;
	const [initialLoading, setInitialLoading] = React.useState(true);
	const [educatorName, setEducatorName] = React.useState<string | null>(educatorNameFromState);
	const [educatorRoster, setEducatorRoster] = React.useState<TeacherStudentRosterDTO | null>(null);
	const [isManageCoursesOpen, setManageCoursesOpen] = React.useState<boolean>(false);
	const [educatorRosterRefresh, setEducatorRosterRefresh] = React.useState(false);
	const [unassignedStudentsRefresh, setUnassignedStudentsRefresh] = React.useState(false);
	const [searchStudentRoster, setSearchStudentRoster] = React.useState<string>('');
	const [searchAllStudents, setSearchAllStudents] = React.useState<string>('');
	const [openModal, setOpenModal] = React.useState<{ open: boolean, table: '' | 'roster' | 'allStudents' }>({ open: false, table: '' });
	const [sitesStudentRoster, setSitesStudentRoster] = React.useState<{ sites: { label: string; value: string }[], countResult: number }>({ sites: [], countResult: 0 });
	const [sitesAllStudents, setSitesAllStudents] = React.useState<{ sites: { label: string; value: string }[], countResult: number }>({ sites: [], countResult: 0 });
	const [loadingSitesStudentRoster, setLoadingSitesStudentRoster] = React.useState<boolean>(false);
	const [loadingSitesAllStudents, setLoadingSitesAllStudents] = React.useState<boolean>(false);
	const [filtersStudentRoster, setFiltersStudentRoster] = React.useState<Record<string, string[] | number[]>>({});
	const [filtersAllStudents, setFiltersAllStudents] = React.useState<Record<string, string[] | number[]>>({});

	const methods = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			sites: {
				roster: [] as string[],
				allStudents: [] as string[],
			}
		}
	});
	const { control, watch } = methods;

	const selectedRosterSites = watch('sites.roster');
	const selectedAllStudentSites = watch('sites.allStudents');

	React.useEffect(() => {
		const handleScroll = (event: Event) => {
			const target = event.target as HTMLElement;
			const scrollLeft = target.scrollLeft;
			const stickyElementLeft = target.querySelectorAll('.sticky-column-left-2');
			if (stickyElementLeft) {
				if (scrollLeft > 0) {
					stickyElementLeft.forEach(element => {
						element.classList.add('scrolled');
					});
				} else {
					stickyElementLeft.forEach(element => {
						element.classList.remove('scrolled');
					});
				}
			}
		};

		const scrollableElements = document.querySelectorAll('.rt-table');
		scrollableElements.forEach(element => {
			element.addEventListener('scroll', handleScroll);
		});
		return () => {
			scrollableElements.forEach(element => {
				element.removeEventListener('scroll', handleScroll);
			});
		};

	}, [isManageCoursesOpen]);

	React.useEffect(() => {
		const loadEducator = async () => {
			if (!educatorName && educatorId) {
				setInitialLoading(true);
				const educator = await educatorsApi.educators.getEducator(educatorId);
				setEducatorName(educator?.name);
				setInitialLoading(false);
			}
		};
		loadEducator();
		setInitialLoading(false);
	}, [educatorId, educatorName]);

	const onAddStudent = async (selectedRows: number[]) => {
		const educatorIdString = educatorId ?? '';
		const parsedEducatorId = parseInt(educatorIdString, 10);
		const educatorRoster: TeacherRosterDTO = {
			teacherId: !isNaN(parsedEducatorId) && parsedEducatorId > 0 ? parsedEducatorId : 0,
			studentsId: selectedRows
		};
		await educatorsApi.educators.addStudentToEducator(educatorRoster);
		setEducatorRosterRefresh(true);
	};

	const onRemoveStudent = async (selectedRows: number[]) => {
		const educatorIdString = educatorId ?? '';
		const parsedEducatorId = parseInt(educatorIdString, 10);
		const educatorRemoveRoster: TeacherRosterDTO = {
			teacherId: !isNaN(parsedEducatorId) && parsedEducatorId > 0 ? parsedEducatorId : 0,
			studentsId: selectedRows
		};
		await educatorsApi.educators.removeStudentToEducator(educatorRemoveRoster);
		setUnassignedStudentsRefresh(true);
	};

	const columns = () => {
		return ([
			{
				Header: 'Id',
				accessor: 'id',
				show: false
			},
			{
				Header: () => (
					<input
						type="checkbox"
					/>
				),
				className: 'sticky-column-left-1 bg-transparent',
				headerClassName: 'sticky-column-left-1',
				id: 'checkbox',
				show: isManageCoursesOpen,
				sortable: false,
				resizable: false,
				width: 55,
				Cell: () => (
					<input
						type="checkbox"
					/>
				),
			},
			{
				Header: t('educators.name'),
				className: isManageCoursesOpen ? 'sticky-column-left-2 bg-transparent' : '',
				headerClassName: isManageCoursesOpen ? 'sticky-column-left-2' : '',
				minWidth: 300,
				accessor: 'fullName',
				Cell: (row: RowInfo) => `${row.original.firstName} ${row.original.lastName}`,
				wrapStyle: 'wrap',
			},
			{
				Header: t('sites.site'),
				accessor: 'siteDetails',
				width: 200,
				Cell: (row: RowInfo) => {
					return row.original?.siteDetails?.join(', ');
				},
				wrapStyle: 'wrap',
			},
			{
				Header: t('students.SSID'),
				accessor: 'studentNumber',
				width: 200,
				wrapStyle: 'wrap',
			},
			{
				Header: t('students.date_of_birth'),
				accessor: 'dateOfBirth',
				width: 200,
				Cell: (row: RowInfo) => {
					return row.original?.dateOfBirth ? DateTime.fromISO(row.original.dateOfBirth?.toString()).toFormat('MM-dd-yyyy') : '';
				},
				wrapStyle: 'wrap',
			},
		]);
	};

	const getEducatorRoster = async <T extends object = { data: TeacherStudentDetailsDTO, totalItems: number }>(
		currentPage: number,
		pageSize: number,
		sortBy: { id: string; desc: boolean } | null,
		searchStudentRoster: string | undefined,
		filtersStudentRoster: Record<string, string[] | number[]>,
		selectedItems: string[]
	): Promise<{ data: T, totalItems: number } | null> => {
		try {
			if (educatorId) {
				const roster = await educatorsApi.educators.getEducatorRoster(educatorId, currentPage, pageSize, sortBy, searchStudentRoster, filtersStudentRoster, selectedItems);
				setEducatorRoster(roster);
				return { data: roster.studentRoster as T, totalItems: roster.totalItems };
			}
			return null;
		} catch (error) {
			console.error('Error fetching educator roster: ', error);
			return null;
		}
	};

	const getUnnasignedStudents = async <T extends object = { data: TeacherStudentDetailsDTO, totalItems: number }>(
		currentPage: number,
		pageSize: number,
		sortBy: { id: string; desc: boolean } | null,
		searchStudentRoster: string | undefined,
		filtersStudentRoster: Record<string, string[] | number[]>,
		selectedItems: string[]
	): Promise<{ data: T, totalItems: number } | null> => {
		try {
			if (educatorId) {
				const unnasignedStudents = await educatorsApi.educators.getUnnasignedStudents(educatorId, currentPage, pageSize, sortBy, searchStudentRoster, filtersStudentRoster, selectedItems);
				return { data: unnasignedStudents.studentRoster as T, totalItems: unnasignedStudents.totalItems };
			}
			return null;
		} catch (error) {
			console.error('Error fetching educator roster: ', error);
			return null;
		}
	};

	const getEducatorRosterIds = async (
		currentPage: number,
		pageSize: number,
		sortBy: { id: string; desc: boolean } | null
	): Promise<{ id: string }[] | null> => {
		try {
			if (educatorId) {
				const studentsIds = await educatorsApi.educators.getEducatorRosterIds(educatorId, currentPage, pageSize, sortBy, searchStudentRoster, filtersStudentRoster);
				if (studentsIds.studentIds) {
					return studentsIds.studentIds.map((id: number) => ({ id: id.toString() }));
				}
			}
			return null;
		} catch (error) {
			console.error('Error fetching educator roster ids: ', error);
			return null;
		}
	};

	const getUnnasignedStudentsIds = async (
		currentPage: number,
		pageSize: number,
		sortBy: { id: string; desc: boolean } | null
	): Promise<{ id: string }[] | null> => {
		try {
			if (educatorId) {
				const studentsIds = await educatorsApi.educators.getUnnasignedStudentsIds(educatorId, currentPage, pageSize, sortBy, searchAllStudents, filtersAllStudents);
				if (studentsIds.studentIds) {
					return studentsIds.studentIds.map((id: number) => ({ id: id.toString() }));
				}
			}
			return null;
		} catch (error) {
			console.error('Error fetching educator roster ids: ', error);
			return null;
		}
	};

	const onCancelManageCourses = () => {
		setManageCoursesOpen(false);
	};

	function displayToolbarAddTranslation(count: number) {
		return t('educators.add_student', { count });
	}

	function displayToolbarRemoveTranslation(count: number) {
		return t('educators.remove_student', { count });
	}

	function onChangeStudentRoster(value: string) {
		setSearchStudentRoster(value);
	}

	function onChangeAllStudents(value: string) {
		setSearchAllStudents(value);
	}

	async function onOpenModalStudentRoster() {
		setOpenModal({ open: true, table: 'roster' });
		if (educatorId) {
			const sites = await educatorsApi.educators.getEducatorSites(educatorId, selectedRosterSites, 'roster');
			setSitesStudentRoster({ sites: sites.sites.map(site => ({ label: site.label, value: site.value })), countResult: sites.countResult });
		}
	}

	async function onOpenModalAllStudents() {
		setOpenModal({ open: true, table: 'allStudents' });
		if (educatorId) {
			const sites = await educatorsApi.educators.getEducatorSites(educatorId, selectedAllStudentSites, 'allStudents');
			setSitesAllStudents({ sites: sites.sites.map(site => ({ label: site.label, value: site.value })), countResult: sites.countResult });
		}
	}

	React.useEffect(() => {
		const updateNbStudents = async () => {
			if (educatorId) {
				setLoadingSitesStudentRoster(true);
				const sites = await educatorsApi.educators.getEducatorSites(educatorId, selectedRosterSites, 'roster');
				setSitesStudentRoster({ sites: sites.sites.map(site => ({ label: site.label, value: site.value })), countResult: sites.countResult });
				setLoadingSitesStudentRoster(false);
			}
		};
		updateNbStudents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRosterSites]);

	React.useEffect(() => {
		const updateNbStudents = async () => {
			if (educatorId) {
				setLoadingSitesAllStudents(true);
				const sites = await educatorsApi.educators.getEducatorSites(educatorId, selectedAllStudentSites, 'allStudents');
				setSitesAllStudents({ sites: sites.sites.map(site => ({ label: site.label, value: site.value })), countResult: sites.countResult });
				setLoadingSitesAllStudents(false);
			}
		};
		updateNbStudents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAllStudentSites]);

	function headerModal() {
		return (
			<div className="d-flex justify-content-between flex-grow-1">
				<div className="d-flex align-items-center">
					{t('educators.filters')}
					{openModal.table === 'roster' && selectedRosterSites.length > 0 && <div className="ms-2 rounded-3 bg-primary-100 px-2 py-1">{selectedRosterSites.length}</div>}
					{openModal.table === 'allStudents' && selectedAllStudentSites.length > 0 && <div className="ms-2 rounded-3 bg-primary-100 px-2 py-1">{selectedAllStudentSites.length}</div>}
				</div>
				<div>
					<Button
						className="btn-ghost-primary pe-0"
						onClick={() => { setOpenModal({ open: false, table: '' }); }}
						icon={<IconClose className="icon-22" />}
						iconPosition="right"
					>
						{t('navigation.close')}
					</Button>
				</div>
			</div>
		);
	}

	if (initialLoading) {
		return <LoadingScreen />;
	}
	return (
		<Page className="d-flex flex-fill" scrollable={false}>
			<Container
				className="scroll-container d-flex flex-column  bg-white"
				isEmpty={!true}
				emptymessage={t('educators.educator_not_found')}
				descriptionmessage={t('educators.description_educator_not_found')}
				image="empty-resources"
			>
				<header className="page-hero px-3">
					<div className="d-flex justify-content-between">
						{isManageCoursesOpen ? (
							<Button
								className="btn-ghost-primary p-0 btn-sm"
								onClick={onCancelManageCourses}
								icon={<IconArrowLeft className="icon-22" />}>
								{t('back')}
							</Button>
						) : (
							<Button
								className="btn-ghost-primary p-0 btn-sm"
								onClick={() => { navigate('/educators'); }}
								icon={<IconArrowLeft className="icon-22" />}>
								{t('educators.back_to_all_educators')}
							</Button>
						)}
					</div>
					<div className="page-hero-title mt-2">
						{educatorRoster && <h5 className="mb-2">{educatorName}</h5>}
					</div>
				</header>
				<div className="container-fluid scroll-container d-flex flex-column flex-grow-1 border-top border-quaternary-600">
					<div className="row">
						<div className={`${isManageCoursesOpen ? 'col-5' : 'col'} px-3 d-flex bg-quaternary-400 py-3 align-items-center`}>
							<h6>{t('educators.student_roster')}</h6>
							<div className="flex-fill ms-3">
								<SearchBar placeholder={t('educators.search_by_name')} onChange={onChangeStudentRoster} />
							</div>
							<Badge count={filtersStudentRoster.sites?.length}>
								<Button icon={<IconFilterList />} className="btn-ghost-primary p-1 ms-1" onClick={onOpenModalStudentRoster} />
							</Badge>
							{!isManageCoursesOpen && <Button
								className="ms-3"
								onClick={() => setManageCoursesOpen(true)}
								icon={<IconAdd className="icon-22" />}>
								{t('educators.manage_roster')}
							</Button>
							}
						</div>
						{isManageCoursesOpen && (
							<div className="col-7 border-start bg-quaternary-400 p-3 d-flex align-items-center">
								<h6>{t('educators.all_students')}</h6>
								<div className="flex-fill ms-3">
									<SearchBar placeholder={t('educators.search_by_name')} onChange={onChangeAllStudents} />
								</div>
								<Badge count={filtersAllStudents.sites?.length}>
									<Button icon={<IconFilterList />} className="btn-ghost-primary p-1 ms-1" onClick={onOpenModalAllStudents} />
								</Badge>
							</div>
						)}
					</div>
					<div className="row flex-grow-1">
						<div className={`${isManageCoursesOpen ? 'col-5' : 'col'} px-0 flex-grow-1`}>
							<ServerSideTable
								className="bg-white scroll-container-wrapper has-sticky-columns table-1"
								columns={columns()}
								fetchData={getEducatorRoster}
								fetchSelectAll={getEducatorRosterIds}
								toolbar={isManageCoursesOpen && true}
								toolbarButtonText={displayToolbarRemoveTranslation}
								toolbarButtonIcon={<IconRemove style={{ height: 18, width: 18 }} />}
								toolbarClearText={t('educators.clear_all')}
								toolbarClearIcon={<IconClose style={{ height: 18, width: 18 }} />}
								toolbarButtonOnClick={onRemoveStudent}
								refresh={educatorRosterRefresh}
								setRefresh={setEducatorRosterRefresh}
								searchText={searchStudentRoster}
								filters={filtersStudentRoster}
							/>
						</div>
						{isManageCoursesOpen && (
							<div className="col-7 border-start px-0 ">
								<ServerSideTable
									className="bg-white scroll-container-wrapper has-sticky-columns table-2"
									columns={columns()}
									fetchData={getUnnasignedStudents}
									fetchSelectAll={getUnnasignedStudentsIds}
									toolbar={isManageCoursesOpen && true}
									toolbarButtonText={displayToolbarAddTranslation}
									toolbarButtonIcon={<IconAdd style={{ height: 18, width: 18 }} />}
									toolbarClearText={t('educators.clear_all')}
									toolbarClearIcon={<IconClose style={{ height: 18, width: 18 }} />}
									toolbarButtonOnClick={onAddStudent}
									refresh={unassignedStudentsRefresh}
									setRefresh={setUnassignedStudentsRefresh}
									searchText={searchAllStudents}
									filters={filtersAllStudents}
								/>
							</div>
						)}
					</div>
				</div>
			</Container>
			<ModalOverlay
				isOpen={openModal.open}
				modalSize="lg"
				onRequestClose={() => {
					openModal.table === 'roster' ? methods.setValue('sites.roster', []) : methods.setValue('sites.allStudents', []);
					setOpenModal({ open: false, table: openModal.table });
				}}
				headerChildren={headerModal()}
				confirmButtonChildren={t('educators.view_student', { count: openModal.table === 'roster' ? sitesStudentRoster.countResult : sitesAllStudents.countResult })}
				cancelButtonChildren={t('educators.clear_all_filters')}
				confirmButtonAction={() => {
					setOpenModal({ open: false, table: openModal.table });
					openModal.table === 'roster' ? setFiltersStudentRoster({ sites: selectedRosterSites }) : setFiltersAllStudents({ sites: selectedAllStudentSites });
				}}
				cancelButtonAction={() => {
					openModal.table === 'roster' ? methods.setValue('sites.roster', []) : methods.setValue('sites.allStudents', []);
				}}
				modalPosition="right"
				hideCloseButton={true}
				shouldCloseOnOverlayClick={false}
				loading={openModal.table === 'roster' ? loadingSitesStudentRoster : loadingSitesAllStudents}
			>
				<div>
					<FormProvider {...methods}>
						{openModal.table && (
							<Controller
								control={control}
								name={`sites.${openModal.table}`}
								render={({ field, fieldState }) =>
									<FormGroup fieldState={fieldState} label={t('sites.sites')}>
										<Select
											options={openModal.table === 'roster' ? sitesStudentRoster.sites : sitesAllStudents.sites}
											isMulti={true}
											{...field}
										/>
									</FormGroup>
								}
							/>
						)}
					</FormProvider>
				</div>
			</ModalOverlay >
		</Page >
	);
}

export default Educators;